import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';


function AppArticle({ list }) {

  function getBlog(id) {
    id = +id;
    return list.find((current) => current.id === id);
  }
  const { key } = useParams();
  const article = getBlog(key);
  
    

  return (
    <section id="about" className="block about-block post-conatiner">
      <Container fluid className="post-content">
              <Row>
                <Col md={12} sm={12}>
                  <Image src={ article.image } className="d-block mx-auto post-image w-100" />
                </Col>
                <div className="title-holder">
                  <h1>{ article.title }</h1>
                </div>
                <Col md={12} sm={12} className="d-block mx-auto post-text">
                  { parse(article.content) } 
                </Col>
              </Row>
              
            </Container>
    </section>
    
  );
}

export default AppArticle;