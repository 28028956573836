import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';



function AppTeamCarusel(props) {
  return (
    <section className="team-carusel block">
      <Container>
        <div className="title-holder">
          <h2>Наша команда</h2>
          <div className="subtitle">немного о наших экспертах</div>
        </div>
        <Carousel  interval={'5000000000000'} >
          {props.list.map((teams) => {
            return (
              <Carousel.Item key={teams.id}>
                <Image className="d-block w-100 rounded" src={teams.image} />
                <Carousel.Caption>
                  <h3>{teams.name}</h3>
                  <p>{teams.designation}</p>
                </Carousel.Caption>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </Container>
    </section>
  );
}

export default AppTeamCarusel;