import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';


import img1 from '../assets/images/img1.jpg';
import img2 from '../assets/images/signs/eac.png'
import img3 from '../assets/images/signs/eda.png'
import img4 from '../assets/images/signs/gmo.png'
import img5 from '../assets/images/signs/lepka.png'

function AppAbout() {

  return (
    <section id="about" className="block about-block">
      <Container fluid>
        <div className="title-holder">
          <h2>О компании</h2>
          <div className="subtitle">На рынке с 2003 года</div>
        </div>
        <Row>
          <Col md={6} sm={12}>
            <Image src={img1} fluid/>
          </Col>
          <Col md={6} sm={12}>
            <Row className="d-flex align-items-center justify-content-center">
              <Col lg={12}>
                <p>Компания представляет широкий асортимент мясных полуфабрикатов. Основные преимущества нашей продукции - ручная лепка и высокое качество сырья.</p>
                <p>Продукция реализуется на территории города Екатеринбурга, Красноуфимского, Артинского, Ачитского районов Свердловской области, Пермского края.</p>
              </Col>
              <Col lg={4} xs={4} className="sign-container">
                <Image src={img2} fluid className='sign-img'/>
              </Col>
              <Col lg={4} xs={4} className="sign-container">
                <Image src={img4} fluid className='sign-img'/>
              </Col>
              <Col lg={4} xs={4} className="sign-container">
                <Image src={img5} fluid className='sign-img'/>
              </Col>       
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default AppAbout;