import AppHero from '../components/hero';
import AppAbout from '../components/about';
import AppTeams from '../components/teams';
import AppContact from '../components/contact';
import Container from 'react-bootstrap/Container';
import AppTeamCarusel from '../components/team-carusel';
import AppQuestions from '../components/questions'

function About(props) {
  return (
    <div className="App">
      <AppHero />
      <Container fluid>
        <AppAbout />
        <AppTeams list={ props.list } />
        < AppTeamCarusel list={ props.list }/>
        < AppQuestions />
        <AppContact />
      </Container>
    </div>
  );
}

export default About;
