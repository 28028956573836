import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



function AppQuestions() {
  return (
    <section id="questions" className="block questions-block">
     	<Container fluid className="questions-content">
        <div className="title-holder">
          <h2>Часто задаваемые вопросы</h2>
          <div className="subtitle">Ответы на самые популярные вопросы, которые нам задают</div>
        </div>
        <Row>
        	<Col>
        		<h4>Как осуществляется доставка продукции партнеру?</h4>
        		<p>Мы подбираем для Вас удобный день недели, за один день, до дня поставки наш менеджер связывается с контактным лицом по телефону и уточняет объем продукции для поставки. Всю продукцию доставляем и выгружаем самостоятельно.</p>
        	</Col>
        </Row>
        <Row>
        	<Col>
        		<h4>Может ли покупатель (не организация) сделать оптовый заказ?</h4>
        		<p>Мы работаем как с физическими, так и с юридическими лицами. Для осуществления поставки по адресу покупателя необходимо сделать заказ на сумму от 10 000 рублей. Поставка осуществляется по территории следующих населенных пунктов: г. Екатеринбург, г. Красноуфимск, пос. Ачит, Красноуфимский и Ачитский районы. Условия доставки согласовываются индивидуально.</p>
        	</Col>
        </Row>
        <Row>
        	<Col>
        		<h4>Предоставляется ли новому партнеру продукция под реализацию?</h4>
        		<p>Поставка продукции под реализацию возможна, условия поставки продукции под реализацию согласовываются с каждым партнером индивидуально.</p>
        	</Col>
        </Row>
      </Container>
    </section>
  );
}

export default AppQuestions;