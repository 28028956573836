import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';


function AppTeams(props) {
  return (
    <section id="teams" className="block teams-block">
      <Container fluid className="gx-5">
        <div className="title-holder">
          <h2>Наша команда</h2>
          <div className="subtitle">немного о наших экспертах</div>
        </div>
        <Row>
          {
            props.list.map(teams => {
              return (
                <Col lg={4} md={4} sm={6}  key={teams.id}>
                  <div className='image'>
                    <Image src={teams.image} className="d-block w-100 img-fluid"/>
                    {/*<div className='overlay'>
                      <div className='socials'>
                        <ul>
                          <li><a href={teams.fbLink}><i className="fab fa-facebook-f"></i></a></li>
                          <li><a href={teams.twitterLink}><i className="fab fa-twitter"></i></a></li>
                          <li><a href={teams.linkedinLink}><i className="fab fa-linkedin-in"></i></a></li>
                        </ul>
                      </div>
                    </div>*/}
                  </div>
                  <div className='content'>
                    <h3>{teams.name}</h3>
                    <span className='designation'>{teams.designation}</span>
                  </div>
                </Col>
              );
            })
          }
        </Row>
      </Container>
    </section>
  );
}

export default AppTeams;