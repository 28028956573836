import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import { useState } from 'react';



function AppHeader() {

  const [expanded, setExpanded] = useState(false);


  function navigation() {
    setExpanded(false);
    goTop();
  };

  function goTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
    <>
      <div className="navbar-contact">
        <a href="tel:+73439462755">+7 (343) 94 6 27 55</a>
      </div>
      <Navbar bg="light" expand="lg" expanded={expanded}>
        <Container>
          <Navbar.Brand href="#" onClick={goTop} >
            <img
              alt=""
              src={require('../assets/logo2.png')}
              width="80"
              height="80"
              className="d-inline-block align-top logo-img"
            />
            <div className='logo-text'>
              <p>МЭЛС РЕКОМЕНДУЕТ</p>
              <p className="subtitle big">Производство мясных полуфабрикатов высокого качества</p>
              <p className="subtitle small">Мясные полуфабрикаты высокого качества</p>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)}/>
          <Navbar.Collapse id="basic-navbar-nav" >
            <Nav className="me-auto" >
              <Nav.Link as={Link} to="/" onClick={navigation}>Главная</Nav.Link>
              <Nav.Link as={Link} to="about" onClick={navigation}>О компании</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}



export default AppHeader;