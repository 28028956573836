const teamsData = [
  {
    id: 1,
    image: require('./1.jpeg'),
    fbLink: 'https://www.facebook.com',
    twitterLink: 'https://www.twitter.com',
    linkedinLink: 'https://www.linkedin.com',
    name: 'Иордан Светлана Эльвиртовна',
    designation: 'Директор предприятия по производственной части',
  },
  {
    id: 2,
    image: require('./2.webp'),
    fbLink: 'https://www.facebook.com',
    twitterLink: 'https://www.twitter.com',
    linkedinLink: 'https://www.linkedin.com',
    name: 'Иордан Артур Андреевич',
    designation: 'Директор предприятия по развитию',
  },
  {
    id: 3,
    image: require('./3.webp'),
    fbLink: 'https://www.facebook.com',
    twitterLink: 'https://www.twitter.com',
    linkedinLink: 'https://www.linkedin.com',
    name: 'Шалунов Вячеслав Дмитриевич',
    designation: 'Заведующий производством',
  }
]


export default teamsData;