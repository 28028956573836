import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Form from 'react-bootstrap/Form';
//import Button from 'react-bootstrap/Button';

function AppContact() {
  return (
    <section id="contact" className="block contact-block">
      <div className="title-holder">
          <h2>Контакты</h2>
          <div className="subtitle">Свяжитесь c нами</div>
      </div>
      <Container className="contact-adress">
        <Row>
          <Col md={6} sm={12} className='address-block'>
            <Row>
              <Col lg={12} >
                <h3>Цех по производству мясных полуфабрикатов</h3>
                <p>Свердловская область, Красноуфимский район, село Чувашково, ул. Советская, д. 39</p>
              </Col>
              <Col lg={12} >
                <h3>Магазин "Мясная лавка"</h3>
                <p>Свердловская область, пос. Ачит,ул. Кривозубова, д. 11 </p>
                <p>Тел. 8 (34394) 6-27-55</p>
              </Col>
            </Row>
          </Col>
          <Col md={6} sm={12}>
            <div className='google-map'>
              <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d365.63577006136563!2d57.88308616712942!3d56.685612331239135!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x43c2c115b7d0e843%3A0x860d6a25e5f36e4f!2z0KfRg9Cy0LDRiNC60L7QstC-!5e0!3m2!1sru!2sru!4v1718115119036!5m2!1sru!2sru"></iframe>
            </div> 
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <div className='contact-info'>
          <ul>
            <li>
              <i className="fas fa-envelope"></i>
              artur-iordan@mail.ru
            </li>
            <li>
              <i className="fas fa-phone"></i>
              8 (34394) 6-27-55
            </li>
            <li>
              <i className="fas fa-map-marker-alt"></i>
              Свердловская область, пос. Ачит,ул. Кривозубова, д. 11 
            </li>
          </ul>
        </div>
      </Container>
    </section>
  );
}

export default AppContact;