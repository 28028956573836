const blogData = [
  {
    id: 1,
    image: require('../../assets/images/blog1.jpg'),
    time: '18 Июня 2024',
    title: 'Производство мясных полуфабрикатов: секреты успеха',
    description: ' В этой статье мы расскажем вам о том, как производится этот вид продукции.',
    content: `
            <p>Мясные полуфабрикаты – это продукты, которые требуют минимальной термической обработки перед употреблением. Они пользуются большой популярностью среди потребителей, так как позволяют быстро приготовить вкусное и сытное блюдо. В этой статье мы расскажем вам о том, как производится этот вид продукции.</p>
            <p>Производство мясных полуфабрикатов начинается с выбора сырья. Для этого используются только качественные и свежие продукты. Затем мясо проходит тщательную обработку, в процессе которой оно очищается от костей, жил и плёнок. После этого мясо измельчается и смешивается с другими ингредиентами, такими как специи, соль и вода.</p>
            <p>Полученная масса формуется в различные виды полуфабрикатов, такие как котлеты, фрикадельки, тефтели, зразы и т. д. Затем полуфабрикаты подвергаются термической обработке, которая позволяет уничтожить все вредные микроорганизмы.</p>
            <p>После этого мясные полуфабрикаты упаковываются в герметичные пакеты или контейнеры, которые обеспечивают их сохранность и свежесть.</p>
            <p>Производство мясных полуфабрикатов – это сложный и ответственный процесс, который требует соблюдения всех санитарных норм и правил. Только в этом случае можно получить качественный продукт, который будет отвечать всем требованиям потребителей.</p>
            
            <h2>Секреты успеха производства мясных полуфабрикатов</h2>
            <p>Чтобы производство мясных полуфабрикатов было успешным, необходимо учитывать следующие факторы:</p>
            <p>
              <ul>
                <li><b>Качество сырья.</b> Качество сырья является ключевым фактором, определяющим качество готовых полуфабрикатов. Сырьё должно быть свежим, качественным и безопасным.</li>
                <li><b>Технология производства.</b> Технология производства должна обеспечивать высокое качество и безопасность полуфабрикатов. Необходимо соблюдать все технологические процессы и нормы.</li>
                <li><b>Оборудование.</b> Оборудование должно быть современным и надёжным. Оно должно обеспечивать высокую производительность и качество продукции.</li>
                <li><b>Персонал.</b> Персонал должен быть квалифицированным и ответственным. Он должен соблюдать все правила и нормы производства.</li>
              </ul>
            </p>
            
          `
  },
  {
    id: 2,
    image: require('../../assets/images/blog2.jpg'),
    time: '1 Июня 2024',
    title: 'Мясные полуфабрикаты',
    description: 'Классификация мясных полуфабрикатов. Мясные полуфабрикаты – это популярные в рационе жителей нашей страны ',
    content: `
            <p>Мясные полуфабрикаты – это популярные в рационе жителей нашей страны продукты. Они долго хранятся и быстро готовятся. Полуфабрикаты производятся из мяса и субпродуктов. Для приготовления некоторых видов может использоваться мука, яйца и специи. В линейке мясных полуфабрикатов  «Mels» представлено несколько полуфабрикатов, которые могут существенно разнообразить ваш рацион и облегчить процесс приготовление пищи.</p>
            <h2>Виды полуфабрикатов из мяса</h2>
            <p>В зависимости от назначения, мясные полуфабрикаты могут быть натуральными, рублеными и панированными. Каждый любитель мясной продукции может купить фарш из говядины, свиной шницель, фрикадельки, куриные нагетсы и другие полуфабрикаты.</p>
            <p>Натуральные полуфабрикаты «Mels» готовятся из охлажденного мяса. Они подразделяются по размерам кусочков на:</p>
            <p>
              <ul>
                <li>порционные</li>
                <li>крупнокусковые</li>
                <li>мелкокусковые</li>
              </ul>
            </p>
            <p>Стейк из говядины, бифштекс, шницель и другие подобные продукты относятся к порционным. Бефстроганов и гуляш относят к мелкокусковым полуфабрикатам. Крупнокусковыми считаются большие куски не нарезанного мяса. Большой популярностью пользуются домашние котлеты из свинины в панировочных сухарях «Mels». Они выручат, если к вам приехали гости или не хочется тратить время на кухне после рабочего дня.</p>
            <h2>Как выбрать качественные мясные полуфабрикаты?</h2>
            <p>При выборе мясных полуфабрикатов следует обращать внимание на следующие факторы:</p>
            <p>
              <ul>
              <li> <b>Состав</b>. В составе качественных мясных полуфабрикатов должны быть только натуральные ингредиенты. Не должно быть искусственных добавок, консервантов и красителей.</li>
              <li><b>Срок годности</b>. Срок годности мясных полуфабрикатов должен быть указан на упаковке. Он должен быть не более 6 месяцев.</li>
              <li><b>Упаковка</b>. Упаковка должна быть целой и неповреждённой. Она должна обеспечивать сохранность продукта.</li>
            </ul>
            </p>
            <p>Соблюдая эти простые правила, вы сможете выбрать качественные мясные полуфабрикаты, которые будут радовать вас своим вкусом и пользой.</p>
            <h2>Интересные факты о производстве мясных полуфабрикатов</h2>
            <p>
              <ul>
              <li><b>Первые мясные полуфабрикаты</b> появились ещё в Древнем Риме. Они представляли собой вяленое мясо, которое использовалось для приготовления различных блюд</li>
              <li><b>В России</b> производство мясных полуфабрикатов началось в XIX веке. Тогда они были доступны только богатым людям.</li>
              <li><b>В настоящее время</b> мясные полуфабрикаты являются одним из самых популярных видов продуктов. Они присутствуют в рационе большинства людей.</li>
            </ul>
            </p>
            <p>Производство мясных полуфабрикатов – это важная часть пищевой промышленности. Оно позволяет обеспечить население качественными и доступными продуктами питания.</p>
          `
  },
  {
    id: 3,
    image: require('../../assets/images/blog3.jpg'),
    time: '15 Июня 2024',
    title: 'Как выбрать качественные мясные полуфабрикаты?',
    description: 'Как выбрать не только вкусное, но и безопасное мясо? Обратите внимание на эти важные моменты.',
    content: `
            <h2>Покупаем мясо: чек-лист по качеству</h2>
            <p> Если вы хотите купить качественный продукт, который будет безопасен для здоровья, обратите внимание на моменты, описанные ниже.</p>
            <p>
              <ul>
                <li>Цвет мяса, будь то говядина, свинина или баранина, не должен иметь коричнево-серых или зеленых оттенков.</li>
                <li>Наличие белого налета говорит о том, что мясо начало портиться.</li>
                <li>Свежее мясо имеет легкий мясной запах без неприятных примесей. </li>
                <li>При надавливании свежее мясо быстро возвращает первоначальную форму. </li>
                <li>Если в мясе есть вкрапления жира, он не должен крошиться.</li>
                <li>Вкрапления жира должны быть равномерными и иметь белый или кремовый оттенок.</li>
                <li>Если мясо продается в заводской упаковке, обратите внимание на дату изготовления и срок годности, а также на рекомендованные условия хранения. Соблюдаются ли они?</li>
                <li>Продажа сырого мяса и полуфабрикатов должна производиться в специальных секциях, отдельно от готовых к употреблению продуктов.</li>
                <li>Если у вас возникают сомнения по поводу качества мясных изделий, то попросите у продавца документы, подтверждающие происхождение, качество и безопасность продукта.</li>
              </ul>
            </p>
            <h2>Охлажденное или замороженное?</h2>
            <p>Выбор между замороженным и охлажденным мясным продуктом может быть не так прост, как кажется на первый взгляд. Если вы уверены в качестве и свежести мяса, то охлажденное, конечно, предпочтительнее. При этом замороженное мясо может не уступать по качеству охлажденному, но стоить дешевле, в том числе благодаря более длительному сроку хранения.</p>
            <p>К сожалению, встречаются случаи, когда размороженное мясо выдается продавцом за охлажденное. При этом вы переплачиваете и теряете возможность дольше сохранить такое мясо.</p>
            <h2>Как понять, что мясо замораживалось и было разморожено?</h2>
            <p>
              <ul>
                <li>Оцените мясо визуально. При открытом хранении на поверхности охлажденного продукта возникает тонкая корочка. Ее оттенок варьируется от светло-розового до темно-розового. Посмотрите на подкожный жир: у мяса после разморозки он принимает красноватый оттенок.</li>
                <li>Пощупайте мясо. Охлажденный продукт будет весьма мягким и эластичным. При касании не должно быть ощущения липкости. Подкожный жир у размороженного мяса не только меняет цвет, но и становится мягким.</li>
                <li>Оцените сухожилия. У «разморозки» они, как правило, красного оттенка и весьма рыхлые, а у охлажденного мяса – гладкие, блестящие и упругие.</li>
                <li> Если вы выбираете кусочек мяса на кости, обратите внимание на цвет костного мозга. Он должен быть бледно-желтым, по консистенции – упругим.</li>
              </ul>
            </p>
            <h2>Что должно насторожить?</h2>
            <p>Не покупайте мясо и мясные полуфабрикаты, если: </p>
            <p>
              <ul>
                <li>продавец отказывается предъявить сопроводительные документы, подтверждающие происхождение, качество и безопасность продукта;</li>
                <li>упаковка нарушена, мясо хранится в загрязненной таре, без этикеток или листов-вкладышей;</li>
                <li>не соблюдены температурные и влажностные условия хранения;</li>
                <li>вы подозреваете, что мясо дефростированное (размороженное) или повторно замороженное.</li>
              </ul>
            </p>
            <p>Продавец должен содержать свое рабочее место в чистоте, строго соблюдать правила личной гигиены, носить чистую специальную одежду (включая головной убор) и перчатки. Оборудование и инвентарь регулярно должны обрабатываться дезинфицирующими средствами по вирусному режиму. Если вы видите, что санитарные правила нарушены, от покупки мяса лучше отказаться.</p>

            
          `
  }
]

export default blogData;