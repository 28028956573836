import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';



function goTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
}

function AppBlog(props) {
  return (
    <section id="blog" className="block blog-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Последние Новости</h2>
          <div className="subtitle">Узнай немного интересного о нашей продукции</div>
        </div>
        <Row >
          {
            props.list.map(blog => {
              return (
                <Col sm={6} md={4} key={blog.id} className="d-flex">
                  <div className='holder d-flex'>
                    <Card>
                      <Card.Img variant="top" src={blog.image} />
                      <Card.Body >
                        <time>{blog.time}</time>
                        <Card.Title>{blog.title}</Card.Title>
                        <Card.Text>
                          {blog.description}
                        </Card.Text>
                        <Link to={`/${blog.id}`} onClick={goTop} className="btn btn-primary btn-read">читать больше <i className="fas fa-chevron-right"></i></Link>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              )
            })
          }
        </Row>
      </Container>
    </section>
  )
}

export default AppBlog;